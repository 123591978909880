import axios from 'axios';

import router from '../router';

let $axios = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
	withCredentials: true,
	headers: {
		// 'x-mock-match-request-body': true
	}
});

$axios.interceptors.request.use(
		(config) => {
			
			

			// config.headers['x-mock-match-request-body'] = true;
			
			
			
			// config.headers.Cookie = `current_user=${Cookie.get('current_user')}`;
			return config;
		},
		(error) => {
			return Promise.reject(error);
		},
);

$axios.interceptors.response.use(
		(response) => {
			return response;
		},
		
);

export default $axios;