import Vue from 'vue'
import VueRouter from 'vue-router'
import AppContent from "../AppContent";

Vue.use(VueRouter)


import LandingPage from "@/components/LandingPage/LandingPage"
const routes = [
	
	{
		path: '/',
	component: AppContent,
children: [
	{
		path: '/',
		component: LandingPage 
}
]
		
	}
	
]

const router = new VueRouter({
	mode: 'history',
	// base: process.env.BASE_URL,
	routes
})


export default router
