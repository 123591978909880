import logo from "./logo.jpg"

const clientData = {
    client_entity: 4,
    attorney_firm: 'Chandler Rogers',
    attorney_name: 'Chandler Rogers',
    attorney_number: '(662) 538- 5990',
    attorney_email: 'chandler@rogerslawgroup.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_frascogna_law+(720p).mp4',
    mainColor: '#242424',
    secondaryColor: '#35740b',
    siteLink: 'http://rogerslawgroup.com/',
    logo
}

export default clientData