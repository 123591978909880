<template>
	<div>
				<router-view></router-view>
			
	</div>
</template>

<script>


	export default {
		name: 'AppContent',
		components: {

			
		},

		data: () => ({}),
	};
</script>
